/*
    Page Header
 */
.page-header {
    padding-top: rem(20);
    margin-bottom: rem(40);

    text-align: center;

    @media (--from-small-screen-w) {
        padding-top: rem(40);
    }


    // Elements
    .badge {
        margin-bottom: rem(20);
    }
}
