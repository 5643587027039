.progress-indicator {
    min-width: rem(230);
    margin-right: rem(45);


    // Elements
    .item {
        position: relative;

        padding-right: rem(40);
        padding-bottom: rem(40);

        text-align: right;


        // Elements
        .icon {
            position: absolute;
            top: 0;
            right: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(25);
            height: rem(25);

            border: 2px solid currentColor;
            border-radius: rem(999);


            // Elements
            svg {
                width: 100%;
                height: 100%;
            }
        }

        .link {
            color: $color-secondary;
            border: none;
        }


        // States
        &:hover .info {
            display: block;
        }


        // Types
        &:not(:last-of-type)::before {
            position: absolute;
            top: rem(23);
            right: rem(12);

            width: 1px;
            height: calc(100% - 13px);
            padding: 0;

            background-color: $color-secondary;
            content: '';
        }

        &:last-of-type::before { // Override default list styles
            content: none;
        }

        &.is-valid .icon {
            background-color: $color-secondary;
        }

        &.is-focussed {
            a {
                color: $color-primary;
            }
        }
    }

    .info {
        position: absolute;
        right: rem(-8);
        z-index: 10;

        display: none;
        width: rem(200);
        margin-top: rem(15);

        text-align: left;
        background-color: $color-secondary-tint-2;
        border-radius: rem(8);


        // Elements
        .fields {
            position: relative;

            padding: rem(15);
            margin-bottom: 0;


            // Elements
            &::before {
                position: absolute;
                top: rem(-8);
                right: rem(10);

                border-right: rem(10) solid transparent;
                border-bottom: rem(15) solid $color-secondary-tint-2;
                border-left: rem(10) solid transparent;

                content: '';
            }
        }

        .field {
            color: $color-tertiary;

            cursor: pointer;


            // Types
            &:last-of-type {
                margin-bottom: 0;
            }


            // States
            &:hover {
                text-decoration: underline;
            }


            // Elements
            &::before {
                // Remove default list styles
                content: none;
            }
        }
    }

    & + .editing-form {
        width: 100%;
    }


    // States
    &.is-sticky {
        position: fixed;
        top: rem(100);

        & + .editing-form {
            margin-left: rem(275);
        }
    }
}
