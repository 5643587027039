/*
    Dialog
 */
.dialog {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;

    display: none;

    background-color: $color-tertiary;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

    transition: all 0.2s ease-in-out;


    // States
    &.is-visible {
        display: block;
    }

    &.is-faux-visible,
    &.is-faux-hidden {
        display: block;

        opacity: 0;
        transform: translateY(20px);
    }

    &.is-hidden {
        display: none;
    }


    // Types
    &.sign-in {
        top: calc(100% - 6px);
        right: 10px;

        @media (--from-tiny-screen-w) {
            right: 30px;
        }
    }


    // Elements
    .inner {
        padding-top: rem(20);

        // Elements
        &::before {
            position: absolute;
            top: 1px;
            right: 10px;

            display: block;
            border: 7px solid transparent;
            border-bottom: 10px solid $color-tertiary;

            content: '';
            transform: translateY(-100%);
        }
    }
}
