/*
    Total
 */
.text-total {

    // Elements
    .title {
        color: $color-secondary-tint-3;
    }
}
