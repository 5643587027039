/*
    Link
 */
a,
.link {
    color: $color-primary;
    text-decoration: none;
    border-bottom: 1px solid;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s;


    // States
    &:hover {
        border-bottom-color: transparent;
    }
}
