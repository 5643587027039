/*
    Navigation
 */
.navigation {
    display: flex;
    height: rem(40);

    @media (--from-tiny-screen-w) {
        height: rem(60);

        border-top: none;
    }

    @media (--to-small-screen-w) {
        display: none; // Hide on small screen
    }


    // Elements
    .item {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: rem(20);
        padding-left: rem(20);

        color: $color-cloud-white;
        border: none;


        // Elements
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;

            display: block;
            width: 100%;
            height: 4px;

            content: '';
        }


        // Types
        &:not(:last-child) {
            border-right: 2px solid $color-secondary-tint-1;
        }


        // States
        &.is-active::after {
            background-color: $color-primary;
        }

        &.is-disabled {
            color: $color-secondary-tint-2;
        }
    }
}
