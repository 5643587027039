/*
    Radio Group
 */
.radio-group {
    display: flex;
    justify-content: space-between;


    // Elements
    .item {
        position: relative;
        margin-left: rem(20);


        // Types
        &:first-child {
            margin-left: 0;
        }


        // Elements
        .input {
            // Hide but don't use display none as this will skip the tabbing ability
            position: absolute;
            top: 0;
            left: 0;

            display: block;
            width: 0;
            height: 0;

            opacity: 0;
        }

        .label {
            position: relative;

            display: block;
            min-height: 200px;
            padding: rem(20);
            padding-top: rem(40);

            text-align: center;

            border: 1px solid $color-secondary-tint-2;
            border-radius: 6px;


            // Elements
            &::before {
                position: absolute;
                top: -1px;
                left: -1px;

                display: block;
                width: calc(100% - 2px);
                height: calc(100% - 2px);

                border: 2px solid $color-primary;
                border-radius: 5px;

                content: '';
                opacity: 0;
            }

            .title,
            .description {
                display: block;
                width: 100%;
                margin-bottom: rem(10);
            }


            // States
            &:hover {
                cursor: pointer;
            }
        }


        // Relationship
        .input:focus + label {
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        }

        .input:checked + .label {
            border-color: $color-primary;


            // Elements
            &::before {
                opacity: 1;
            }
        }
    }


    // Relationship
    & + .error {
        justify-content: center;
    }
}
