/*
    Footer
 */
.footer {
    width: 100%;
    padding: 2rem 15px;
    color: $color-tertiary;
    background-color: $color-darker-grey;
    border-top: 1px solid $color-dark-grey;

    &.container-fluid {
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .footer-section {
        margin-bottom: 15px;
    }

    .footer-logo-container {
        img {
            width: 100%;
            max-width: 230px;
            margin-top: 10px;
            
            @media (min-width: 576px) {
                margin: auto;
                margin-top: 0;
            }
        }
    }

    a, .link {
        // Overriding the stupid styles in _link.scss.
        color: $color-grey;
        text-decoration: none;
        border-bottom: none;

        &:focus, &:hover {
            color: $color-hover-blue;
            text-decoration: underline;
        }
    }

    h3 {
        margin-top: 5px;
        color: $color-cloud-white;
        font-size: 24px;
        font-weight: $weight-bold;
    }

    hr {
        margin: 20px 0;
        background-color: $color-dark-grey;
        border: 0;
    }

    ul {
        padding-left: 0;
        list-style-type: none;

        li {
            margin-bottom: 5px;
            color: $color-grey;
            font-size: 20px;
            list-style: none;

            &::before {

                // Overriding the stupid styles in _list.scss.
                padding-right: 0;
                content: none;
            }
        }
    }

    .col-offset-3 {
        @media (--from-normal-screen-w) {
            margin-left: 25%;
        }
    }
}
