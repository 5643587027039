/*
    Banner
 */
.banner {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    min-height: rem(360);
    padding-top: rem(40);
    color: $color-tertiary;

    background-color: $color-secondary;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--from-small-screen-w) {
        min-height: rem(400);
    }

    @media (--from-medium-screen-w) {
        height: rem(460);
    }

    @media (--from-normal-screen-w) {
        height: rem(480);
    }

    @media (--from-x-large-screen-w) {
        height: rem(560);
    }


    // Types
    &.image-1 {
        background-image: url('/assets/images/banner/dashboard-1.jpg');
    }

    &.image-2 {
        background-image: url('/assets/images/banner/dashboard-2.jpg');
    }

    &.image-3 {
        background-image: url('/assets/images/banner/dashboard-3.jpg');
    }

    &.is-loading-fade .total {
        opacity: 0;
        transform: translateX(20px);

        @media (--from-normal-screen-w) {
            transform: translateY(20px);
        }
    }


    // Elements
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.4);

        content: '';

        @media (--from-normal-screen-w) {
            height: 50%;

            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        }
    }

    .inner {
        position: relative;

        width: 100%;
    }

    .title-sequence {
        @media (--from-normal-screen-w) {
            display: flex;

            text-shadow: 0 6px 10px rgba(0, 0, 0, 0.6);
        }


        // Elements
        .sub-heading {
            margin-bottom: 0;

            font-size: rem(14);

            @media (--from-medium-screen-w) {
                font-size: rem(16);
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .title {
            flex-grow: 1;
        }

        .total {
            transition: 0.2s ease-out;

            @media (--from-normal-screen-w) {
                padding-left: rem(20);
            }

            @media (--from-large-screen-w) {
                padding-left: rem(30);
            }
        }
    }

    .banner-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding-top: rem(0);
        margin-bottom: rem(16);

        @media (--from-normal-screen-w) {
            padding-top: rem(20);
        }


        // Elements
        .filter-bar {
            flex-grow: 1;
            width: 100%;
            margin-bottom: rem(16);
        }

        .filter-buttons-left {
            .button {
                margin-right: rem(16);
            }
        }

        .button.export {
            flex-basis: auto;
            width: auto;
            min-width: auto;
        }

        .dropdown-actions {

            @media (--to-normal-screen-w) {
                display: none;
            }

            // Elements
            .filter-buttons {
                display: flex;
            }


            .main-button {
                @media (--to-large-screen-w) {

                }
            }
        }
    }

    .hide-to-desktop {
        @media (--to-normal-screen-w) {
            display: none !important;
        }
    }

    .hide-from-desktop {
        @media (--from-normal-screen-w) {
            display: none !important;
        }
    }
}
