/*
    Home
 */

@import '../variables';

.home {
    position: relative;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 40px;
    background-color: $color-secondary;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // Types
    &.image-1 {
        background-image: url('/assets/images/banner/dashboard-1-large.jpg');
    }

    &.image-2 {
        background-image: url('/assets/images/banner/dashboard-2-large.jpg');
    }

    &.image-3 {
        background-image: url('/assets/images/banner/dashboard-3-large.jpg');
    }

    // Elements
    a {
        color: $color-cloud-white;
        font-size: 20px;
        font-weight: $weight-bold;
    }

    h3 {
        color: $color-cloud-white;
        font-size: 48px;
        font-weight: $weight-bold;
        text-shadow: 4px 4px 12px $color-pure-black;
    }

    h4 {
        color: $color-cloud-white;
        font-weight: $weight-bold;
        text-shadow: 4px 4px 12px $color-pure-black;
    }

    .main-inner {
        width: 945px;
        max-width: 100%;
        min-height: auto;
        padding: 0 15px;
        margin: auto;
    }

    .title {
        margin-bottom: 40px;
        color: $color-tertiary;
        line-height: 57px;
        text-align: center;

        br {
            @media (--from-normal-screen-w) {
                display: none;
            }
        }
    }

    .introduction {
        font-size: 20px;
        text-align: center;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

        @media (--from-normal-screen-w) {
            text-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
        }

        @media (--from-medium-screen-w) {
            margin-right: 74px;
            margin-left: 74px;
        }


        .sub-heading {
            margin-bottom: 40px;
            color: $color-tertiary;
        }

        .sub-heading {
            font-size: 36px;
        }

        .form {
            padding: 50px 40px;
            background-color: rgba(0, 0, 0, 0.65);

            p {
                color: $color-cloud-white;
                font-size: 1.13em;
            }

            .intro {
                text-align: left;
            }

            .title {
                margin-bottom: 70px;
            }

            .actions {
                button {
                    width: 155px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                p {
                    font-size: 20px;
                    text-decoration: underline;
                }
            }
        }
    }
}
