/*
    Button
 */
button,
button[type='submit'] {
    display: inline-block;
    padding: 0;

    line-height: 1;
    white-space: nowrap;

    background: none;
    border: none;
    border-radius: 3px;
    appearance: none;

    // States
    &:hover {
        cursor: pointer;
    }

    // Types
    &.label {
        white-space: inherit;
    }
}

a.button {
    display: inline-flex;
    align-items: center;
    flex-basis: rem(260);
    flex-shrink: 0;
    justify-content: center;

    text-decoration: none;
}

a.button,
.button,
button.button,
button[type='submit'].button {
    position: relative;

    width: rem(240);
    max-width: 100%;
    padding: rem(8) rem(10);

    color: $color-secondary-tint-1;
    font-size: rem(16);
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s;

    @media (--from-small-screen-w) {
        height: rem(46);
        padding: rem(10) rem(15);
    }

    @media (--from-normal-screen-w) {
        width: rem(260);
        height: 3rem;
        padding: rem(10) rem(20);

        font-size: rem(16);
        line-height: 30px;
    }


    // Elements
    &::before {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border: inherit;
        border-width: 1px;
        border-radius: inherit;

        content: '';
        opacity: 0;

        transition: opacity 0.1s;
    }

    .icon {
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        width: 1em;
        height: 1em;

        font-size: 22px;
        vertical-align: middle;

        @media (--from-normal-screen-w) {
            font-size: 28px;
        }


        // Alignment
        &.left {
            margin-right: rem(8);
            margin-left: rem(-4);
        }

        &.right {
            margin-right: rem(-4);
            margin-left: rem(8);
        }
    }


    // States
    &:hover {
        cursor: pointer;
        border-bottom-color: inherit;


        // Elements
        &::before {
            opacity: 1;
        }
    }

    &:not(.text):focus {
        box-shadow: none;
        outline: none;


        // Elements
        &::before {
            opacity: 1;
        }
    }

    &[disabled],
    &.disabled {
        pointer-events: none;
        opacity: 0.2;


        // States
        &:focus {
            box-shadow: none;


            // Elements
            &::before {
                display: none;
            }
        }
    }

    &.primary {
        color: $color-tertiary;
        font-weight: $weight-bold;
        background-color: $color-primary;
        border-color: transparent;


        // States
        &:hover {
            background-color: $color-primary-tint-1;


            // Elements
            &::before {
                display: none;
            }
        }
    }

    &.secondary {
        color: $color-primary;
        background-color: $color-tertiary;
        border-color: $color-primary;
    }

    &.tertiary {
        color: $color-tertiary;
        border-color: $color-tertiary;
    }

    &.quarternary {
        color: $color-tertiary;
        font-weight: $weight-regular;
        background-color: $color-primary-shade-2;
        border-color: transparent;


        // States
        &:hover {
            background-color: $color-primary-shade-1;
        }
    }

    &.quinary {
        color: $color-quarternary;
        border-color: $color-quarternary;
    }

    &.septenary {
        color: $color-tertiary;
        font-weight: $weight-regular;
        background-color: $color-quinary;
        border-color: transparent;


        // States
        &:hover {
            background-color: $color-quinary-tint-1;


            // Elements
            &::before {
                display: none;
            }
        }
    }

    &.warning {
        color: $color-tertiary;
        font-weight: $weight-regular;
        background-color: $color-quarternary;
        border-color: transparent;


        // States
        &:hover {
            background-color: $color-quarternary-tint-1;


            // Elements
            &::before {
                display: none;
            }
        }
    }


    // Sizes
    &.medium,
    &.small {
        flex-basis: auto;
        width: auto;
        height: auto;

        font-size: rem(16);
        line-height: 1;


        // Elements
        .icon {
            font-size: rem(20);
        }
    }

    &.medium {
        padding: rem(9) rem(15) rem(7);
    }

    &.small {
        padding: rem(6) rem(8) rem(4);
    }


    // Types
    &.block {
        display: block;

        border-color: transparent;
        border-radius: 0;
    }

    &.text {
        padding-right: 0;
        padding-left: 0;

        border-color: transparent;
        border-radius: 0;
    }
}

// Redesign button
// This simply override button style above.

.button.alt {
    width: auto;
    height: auto;
    min-width: 112px;
    padding: 13px 28px;
    font-size: 20px;
    font-weight: $weight-medium;
    line-height: 24px;
    white-space: unset;
    border: 3px solid transparent;
    border-radius: 3px;

    &.secondary {
        color: $color-cloud-white;
        background-color: transparent;
        border-color: $color-cloud-white;
    }
}

