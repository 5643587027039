/*
    Button Dropdown
 */
.button-dropdown {
    position: relative;


    // States
    &.is-open {

        .trigger-wrapper {

            .main-button {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;

                box-shadow: none !important; // Ugly
            }

            .trigger {
                border-bottom-right-radius: 0;
            }

            .chevron {
                transform: rotate(180deg);
            }
        }

        .buttons {
            display: block;
        }


        // Sub types
        &.align-top {

            .trigger-wrapper .main-button {
                border-radius: 3px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }

            .trigger {
                border-top-right-radius: 0;
                border-bottom-right-radius: 3px;
            }
        }

        .secondary {

        }
    }

    &.is-disabled {
        opacity: 0.4;
    }

    &.is-loading {

        &::after {
            left: rem(35);

            width: rem(15);
            height: rem(15);
            margin: rem(-11);

            border-width: rem(3);
        }

        &::before {
            content: none;
        }
    }


    // Themes
    &.secondary {

        .main-button,
        .buttons .item {
            color: $color-primary-tint-1;

            background-color: $color-tertiary;
            border-color: $color-primary-tint-1;

            &:hover {
                color: $color-primary-tint-3;
            }
        }

        .trigger {
            color: $color-primary-tint-1;

            &:hover {
                color: $color-primary-tint-3;
                background-color: transparent;
            }
        }

        .buttons {
            border: 1px solid $color-primary-tint-1;
            border-top: none;
        }
    }

    &.tertiary {

        .main-button,
        .buttons .item {
            background-color: $color-quinary;

            &:hover {
                background-color: $color-quinary-tint-1;
            }
        }

        .buttons .item {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        .trigger:hover {
            background-color: $color-quinary-tint-1;
        }

        .buttons {
            border-top: none;
        }
    }


    // Alignment
    &.align-right .buttons {
        right: 0;
        left: auto;

        .item:first-child {
            border-top-left-radius: 3px;
        }
    }

    &.align-top {

        .buttons {
            top: auto;
            bottom: 100%;

            flex-direction: row-reverse;
            margin-top: 0;
            margin-bottom: -1px;


            // Shared
            &,
            & .item:last-child {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &,
            & .item:first-child {
                border-top-right-radius: 3px;
                border-top-left-radius: 3px;
            }


            // Elements
            .item {
                border: none;
                border-bottom: 1px solid $color-primary-tint-1;
            }
        }


        // Sub types
        &.secondary {

            .buttons {
                border: 1px solid $color-primary-tint-1;
                border-bottom: none;
            }
        }
    }


    // Widths
    &.width-auto {

        .trigger-wrapper .main-button {
            width: auto;
            min-width: rem(50);
            padding-right: rem(70);
            padding-left: rem(20);

            &.no-label {
                width: auto;
                min-width: rem(50);
                padding-right: 0;
                padding-left: 0;

                & + .trigger {
                    border-radius: 3px;
                }
            }
        }
    }


    // Elements
    .trigger-wrapper {
        position: relative;

        height: rem(52);
        min-width: rem(50);


        // Elements
        .main-button {
            min-width: rem(280);
            padding-right: rem(60);
            padding-left: rem(60);

            &.no-label {
                width: auto;
                min-width: rem(50);
                padding-right: 0;
                padding-left: 0;

                & + .trigger {
                    border-radius: 3px;
                }
            }
        }
    }

    .main-button,
    .buttons .item,
    .trigger {
        height: 100%;
        color: $color-tertiary;

        background-color: $color-primary;
        border-color: transparent;

        box-shadow: none;


        // States
        &:hover {
            background-color: $color-primary-tint-1;
            border-color: transparent;
        }

        &:focus {
            text-decoration: underline;
            box-shadow: inset 0 0 rem(5) $color-secondary-tint-1;

            outline: none;
        }

        &::before {
            display: none;
        }
    }

    .trigger {
        position: absolute;
        top: 0;
        right: 0;

        width: rem(50);
        height: 100%;

        line-height: 0;
        text-align: center;

        background-color: transparent;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        content: '';


        // Elements
        .icon {
            display: inline-block;
            width: 1em;
            height: 1em;

            font-size: rem(32);
        }

        .chevron {
            font-size: rem(20);
        }
    }

    .main-button,
    .buttons .item {
        padding: rem(16) rem(20);

        color: $color-tertiary;
        line-height: 1;
    }

    .buttons {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9999;

        display: none;
        width: 100%;
        min-width: rem(280);
        margin-top: -1px; // Slight overlap for border
        overflow: hidden;

        font-size: rem(20);
        text-align: center;


        // Elements
        &,
        & .item:last-child {
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .item {
            display: block;
            width: 100%;

            border: none;

            transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;


            // Mixed
            &,
            &:hover {
                border-top: 1px solid $color-primary-tint-1;
            }
        }
    }
}
