/*
    Status
 */
.status {
    display: flex;
    align-items: center;
    padding: rem(20) 0;


    // Elements
    .stage,
    &::before {
        box-sizing: border-box;
        display: block;
        width: rem(10);
        height: rem(10);
        margin-right: rem(8);

        color: $color-secondary-tint-1;
        font-size: 6px;

        border: 2px solid currentColor;
        border-radius: 100px;

        content: '';
    }

    .stage {

        // Types
        &.is-complete {
            background-color: currentColor;
        }

        &:not(:last-of-type) {
            margin-right: rem(3);
        }
    }


    // Types
    &.ready,
    &.invited,
    &.approved,
    &.published,
    &.enabled,
    &.disabled {

        // Elements
        .stage {
            display: none;
        }
    }

    &.draft::before {
        content: none;
    }

    &.ready::before,
    &.invited::before {
        color: $color-senary;
        background-color: currentColor;
    }

    &.approved::before {
        color: $color-primary;
        background-color: currentColor;
    }

    &.published::before,
    &.enabled::before {
        color: $color-quinary;
        background-color: currentColor;
    }

    &.disabled::before {
        color: $color-quarternary;
        background-color: currentColor;
    }
}
