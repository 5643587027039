/*
    List
 */
ul,
ol {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: rem(20);

    list-style-position: inside;


    // Elements
    li {
        margin-bottom: rem(10);
    }


    // Types
    &.simple li {
        margin-bottom: 0;

        // Elements
        &::before {
            display: none;
        }
    }
}

ul {
    list-style-type: none;


    // Elements
    li::before {
        padding-right: rem(20);

        content: '•';
    }
}

ol li::before {
    padding-right: rem(10);

    content: '';
}
