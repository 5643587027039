/*
    Page
 */
* {
    box-sizing: border-box;
}

html {
    position: relative;

    min-width: 320px;
    min-height: 100%;

    background-color: $color-secondary;
}

body {
    width: 100%;

    background-color: $color-tertiary;
}

.page-single {
    padding-bottom: rem(30);

    @media (--from-medium-screen-w) {
        padding-top: rem(30);
        padding-bottom: rem(50);
    }
}
