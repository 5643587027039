/*
    Fonts
 */
@font-face {
    font-family: National;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/national/book.woff') format('woff');
}

@font-face {
    font-family: National;
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/national/medium.woff') format('woff');
}

@font-face {
    font-family: Pure Pakati;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/pure-pakati/regular.woff') format('woff');
}

@font-face {
    font-family: Pure Pakati Notch;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/pure-pakati-notch/regular.woff') format('woff');
}