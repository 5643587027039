/*
    Page Actions
 */
.page-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999999; // 6

    width: 100%;
    padding-top: rem(20);

    background-color: $color-primary-tint-3;

    transition: transform 0.2s ease-in-out;

    @media (--to-small-screen-w) {
        display: none;
    }

    // Elements
    .button-group {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: rem(20);


        // Elements
        .item:not(:last-child) {
            flex-grow: 0;
            margin-right: rem(20)
        }

        .link {
            line-height: 1;
        }
    }

    .notifications {
        position: absolute;
        bottom: 100%;
        left: 0;

        margin: 0;
    }
}
