/*
    Select
 */
.select {
    font-size: rem(18);


    // Elements
    .Select__control {
        position: relative;
        min-height: 43px;
        padding-bottom: 1px;

        border: 1px solid $color-secondary-tint-2;
        border-radius: 3px;


        // Elements
        .Select__placeholder {
            width: calc(100% - 20px);
            padding: rem(12) rem(15);

            color: $color-secondary-tint-3;
            line-height: 1;
            opacity: 0.5;
        }

        .Select__input {
            height: 38px;
            padding: 0 rem(15);
            margin: 0;


            // Elements
            input {
                padding: rem(3) 0;
            }
        }

        .Select__clear-zone {
            position: absolute;
            top: 0;
            right: 30px;
            width: 20px;
            height: 30px;
            padding: 0;


            // Elements
            .Select__clear {
                position: absolute;
                top: 5px;
                left: 0;

                width: 100%;

                font-size: 28px;
                text-align: center;
            }
        }

        .Select__arrow-zone {
            width: 26px;
            padding: 0;


            // Elements
            .Select__arrow {
                position: absolute;
                top: 16px;
                right: 10px;

                border: 6px solid transparent;
                border-top: 7px solid $color-secondary-tint-1;
            }
        }

        .Select__loading-zone {
            display: none !important;
        }

        .Select__value {
            padding-left: rem(15);

            color: $color-secondary;

            .custom-render {
                display: flex;
                align-items: center;
            }
        }

        .Select__item {
            position: relative;

            display: inline-flex;
            align-items: center;
            padding: 0;

            background-color: transparent;
            border: none;


            // Elements
            .Select__item-label {
                padding-right: 0;
                padding-left: 0;

                border: none;


                // Elements
                .custom-render {
                    padding: rem(6) rem(40) rem(6) rem(10);
                    overflow: hidden;

                    color: $color-tertiary;
                    font-size: rem(18);

                    background-color: $color-primary;
                    border: none;
                    border-radius: 6px;

                    // Types
                    &.is-unverified {
                        background-color: $color-senary;
                    }

                    &.is-deleted {
                        background-color: $color-secondary-tint-3;
                    }
                }
            }

            .Select__item-icon {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;

                order: 2;
                padding-right: rem(10);

                color: $color-tertiary;
                font-size: 26px;
                line-height: 1;

                background-color: transparent;
                border: none;



                // States
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

    .Select__menu {
        max-height: 200px;
        margin-top: -1px;

        border: 1px solid $color-primary;
        border-top-color: $color-tertiary-shade-2;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        // Elements
        .Select__menu-list {
            max-height: 198px;
            overflow-y: auto;
        }

        .Select__option {
            position: relative;

            padding: rem(8) rem(15);

            color: $color-secondary-tint-2;

            &--is-focused,
            &--is-selected {
                background-color: RGB(222, 235, 255);
            }

            // Elements
            .custom-render {

                // Types
                &.is-unverified,
                &.is-deleted {

                    &::after {
                        display: inline-block;
                        padding: rem(3) rem(6) rem(2);
                        margin-left: rem(5);

                        color: $color-tertiary;
                        font-size: rem(12);
                        line-height: 1;
                        vertical-align: middle;

                        border-radius: 4px;
                    }
                }

                &.is-unverified {

                    // Elements
                    &::after {
                        background-color: $color-senary;
                        content: 'Unverified';
                    }
                }

                &.is-deleted {

                    // Elements
                    &::after {
                        background-color: $color-secondary-tint-3;
                        content: 'Deleted';
                    }
                }
            }
        }
    }

    .pill {
        margin-left: rem(10);
    }


    // States
    &.has-value {


        // Elements
        .Select__placeholder {
            color: $color-secondary;
            opacity: 1;
        }
    }

    &.is-loading {
        &::before {
            display: none;
        }

        &::after {
            right: rem(22);
            left: auto;

            font-size: rem(10);
        }

        // Hide
        .Select__arrow-zone {
            opacity: 0;
        }
    }

    .Select__control--is-focused  {
        border: 1px solid $color-primary;
    }

    .Select__control--is-open  {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .Select__control--is-disabled  {
        background-color: $color-tertiary;

        opacity: 0.3;

        & > * {
            display: none;

            opacity: 0;
        }
    }


    // Types
    &.multiple.has-value .Select__input {
        padding-left: rem(8);
    }
}
