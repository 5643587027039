/*
    Variables
    - Breakpoints (Horizontal)
    - Asset Locations
    - Colors
    - Typography
    - Widths
    - Heights
 */



/*
    Breakpoints
 */
@custom-media --to-tiny-screen-w (max-width: 400px);
@custom-media --to-small-screen-w (max-width: 600px);
@custom-media --to-medium-screen-w (max-width: 800px);
@custom-media --to-normal-screen-w (max-width: 1024px);
@custom-media --to-large-screen-w (max-width: 1200px);
@custom-media --to-x-large-screen-w (max-width: 1440px);
@custom-media --to-max-screen-w (min-width: 2060px);

@custom-media --from-tiny-screen-w (min-width: 401px);
@custom-media --from-small-screen-w (min-width: 601px);
@custom-media --from-medium-screen-w (min-width: 801px);
@custom-media --from-normal-screen-w (min-width: 1025px);
@custom-media --from-large-screen-w (min-width: 1201px);
@custom-media --from-x-large-screen-w (min-width: 1441px);
@custom-media --from-max-screen-w (min-width: 2020px);



/*
    Asset Locations
 */
$folder-image: ../images; /* No quotes, causes issues with Webpack */
$folder-icon: /assets/icons; /* No quotes, causes issues with Webpack */



/*
    Color Naming Conventions:
    - primary
    - secondary
    - tertiary
    - quaternary
    - quarternary

    // Lighter
    - tint-1
    - tint-2

    // Darker
    - shade-1
    - shade-2

    Color names generated at http://chir.ag/projects/name-that-color/
 */
$color-pure-black:            rgb(23, 23, 23);    // #171717 - DBG: Colours marked with HEX are from the Digital Brand Guidelines
$color-coal:                  rgb(51, 51, 51);
$color-dusty-gray:            rgb(115, 115, 115);
$color-silver:                rgb(155, 155, 155);
$color-title-grey:            rgb(177, 178, 179);
$color-shinzo:                rgb(190, 190, 190);
$color-mercury:               rgb(222, 222, 222); // #DEDEDE - DBG
$color-concrete:              rgb(240, 240, 240);
$color-cloud-white:           rgb(255, 255, 255);
$color-grey:                  rgb(203, 203, 203); // #CBCBCB
$color-dark-grey:             rgb(94, 94, 94); // #5E5E5E
$color-darker-grey:           rgb(26, 26, 24); // #1A1A18

$color-black-pearl:           rgb(19, 65, 84);
$color-eden:                  rgb(28, 87, 112);   // #1C5770 - DBG
$color-blumine:               rgb(53, 112, 136);  // #357088 - DBG
$color-eastern-blue:          rgb(32, 138, 183);
$color-pacific-blue:          rgb(0, 147, 222);  // #0093DE - DBG
$color-picton-blue:           rgb(83, 189, 234);
$color-hover-blue:            rgb(35, 82, 124); // #23527c
$color-seagull:               rgb(126, 205, 239);
$color-sail:                  rgb(169, 222, 244);
$color-humming-bird:          rgb(212, 238, 249);

$color-bright-red:            rgb(240, 28, 28);   // #F01C1C
$color-monza:                 rgb(207, 17, 30);   // #CF111E - DBG
$color-minx:                  rgb(230, 39, 52);
$color-hot-cinnamon:          rgb(251, 176, 17);  // #FBB011 - DBG
$color-mint:                  rgb(114, 201, 28);  // #72C91C - DBG
$color-lime:                  rgb(124, 215, 38);

$color-primary:               $color-pacific-blue;
$color-primary-shade-4:       $color-black-pearl;
$color-primary-shade-3:       $color-eden;
$color-primary-shade-2:       $color-blumine;
$color-primary-shade-1:       $color-eastern-blue;
$color-primary-tint-1:        $color-picton-blue;
$color-primary-tint-2:        $color-seagull;
$color-primary-tint-3:        $color-sail;
$color-primary-tint-4:        $color-humming-bird;

$color-secondary:             $color-pure-black;
$color-secondary-tint-1:      $color-coal;
$color-secondary-tint-2:      $color-dusty-gray;
$color-secondary-tint-3:      $color-silver;

$color-tertiary:              $color-cloud-white;
$color-tertiary-shade-1:      $color-concrete;
$color-tertiary-shade-2:      $color-mercury;
$color-tertiary-shade-3:      $color-shinzo;

$color-quarternary:           $color-monza;
$color-quarternary-tint-1:    $color-minx;

$color-quinary:               $color-mint;
$color-quinary-tint-1:        $color-lime;

$color-senary:                $color-hot-cinnamon;

$color-warning:               $color-monza;
$color-success:               $color-mint;

$color-required:              $color-bright-red;



/*
    Typography
    - Typefaces
    - Weights
 */
$font-national: National, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$font-pure-pakati: 'Pure Pakati', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$font-pure-pakati-notch: 'Pure Pakati Notch', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;

$font-primary: $font-national;
$font-secondary: $font-pure-pakati;
$font-tertiary: $font-pure-pakati-notch;

$weight-regular: 400;
$weight-medium:  600;
$weight-bold:    800;

/*
    Widths
 */
$constrain-width-large:   rem(1440);
$constrain-width:         rem(940);
$constrain-width-medium:  rem(740);
$constrain-width-small:   rem(600);
$constrain-width-tiny:    rem(300);



/*
    Padding
 */
$padding-width: rem(20);
