/*
    Line
 */
hr {
    height: 1px;
    margin-top: rem(20);
    margin-bottom: rem(20);

    background-color: $color-tertiary-shade-2;
    border: 0;
}
