/*
    Calendar
 */
.calendar {
    background-color: $color-tertiary;


    // Elements
    .DayPicker {
        padding: rem(10);

        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }

    .DayPicker-Day {
        width: 34px;
        height: 34px;
        padding: 0;
    }


    // Month
    .DayPicker-Month {
        margin: 0;
    }


    // Days
    .DayPicker-Weekday,
    .DayPicker-Day {
        font-size: rem(14);
    }

    .DayPicker-Weekday abbr[title] {
        color: $color-secondary-tint-2;
        text-decoration: none;
    }

    .DayPicker-Day {
        color: $color-secondary-tint-2;
    }

    .DayPicker-Day--today {
        color: $color-primary;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        color: $color-tertiary;
        background-color: $color-primary;
    }

    .DayPicker-Day--outside {
        color: $color-tertiary-shade-2;
    }


    // Title
    .DayPicker-Caption {

    }


    // Navigation
    .DayPicker-NavButton {
        display: flex;
        align-items: center;
        justify-content: center;

        background: none;


        // Types
        &::before {
            position: relative;

            display: block;
            height: 0;
            border: 6px solid transparent;

            content: '';
        }

        &.DayPicker-NavButton--prev::before {
            left: -6px;
            border-right: 8px solid rgb(51, 51, 51);
        }

        &.DayPicker-NavButton--next::before {
            left: 6px;
            border-left: 8px solid rgb(51, 51, 51);
        }
    }
}
