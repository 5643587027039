/*
    Page Navigation
 */
.page-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(10);


    // Elements
    .button.back {
        padding-left: 0;
        margin-bottom: 0;
    }
}
