/*
    Global Notifications
 */
.global-notifications {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999; // 5

    display: none;
    width: 100%;


    // Types
    &.type-error .inner {
        background-color: $color-quarternary;
    }

    &.type-warning .inner {
        background-color: $color-senary;
    }


    // States
    &.is-open {
        display: block;
    }

    // This needs to be here only when
    &.is-offset {
        bottom: rem(86); // Height of the page-actions block

        @media (--from-normal-screen-w) {
            bottom: rem(92);
        }
    }

    &.is-visible .inner {
        transform: translateY(-100%);
    }


    // Elements
    .inner {
        position: absolute;
        top: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        color: $color-tertiary;
        font-size: rem(16);
        text-align: center;

        background-color: $color-quinary;

        transition: transform 0.2s ease-in-out;
        transition-delay: 0.1s;

        @media (--from-normal-screen-w) {
            font-size: rem(20);
        }
    }

    .item {
        padding: rem(10) 0;
        margin: 0;

        @media (--from-normal-screen-w) {
            padding: rem(20) 0;
        }
    }
}
