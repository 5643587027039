/*
    Result View
 */
.result-view {

    @media (--from-medium-screen-w) {
        display: flex;
        margin-right: rem(-30);
        margin-left: rem(-30);
    }

    // Elements
    .content,
    .sidebar {

        @media (--from-medium-screen-w) {
            padding-right: rem(30);
            padding-left: rem(30);
        }
    }

    .content {

        @media (--from-medium-screen-w) {
            flex-basis: 70%;
        }
    }

    .sidebar {
        padding-top: rem(20);

        @media (--to-medium-screen-w) {
            border-top: 1px solid $color-tertiary-shade-2;
        }

        @media (--from-medium-screen-w) {
            flex-basis: 320px;
            flex-shrink: 0;
            padding-top: rem(5);
        }
    }
}
