/*
    Pill
 */
.pill {
    display: inline-block;
    padding: rem(2) rem(6);

    color: $color-tertiary;
    font-size: rem(13);

    background-color: $color-tertiary-shade-3;
    border-radius: rem(3);


    // Elements
    &.warning {
        background-color: $color-senary;
    }

    &.primary {
        background-color: $color-primary;
    }
}
