/*
    Page Section
 */
.page-section {
    position: relative;

    margin-bottom: rem(20);


    // Elements
    .inner {
        width: 100%;
        padding-top: rem(20);
        padding-bottom: rem(20);
        margin-right: 0;
        margin-left: 0;


        // Types
        &.full-width {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .section-intro {
        position: relative;

        display: flex;
        align-items: center;


        // Elements
        .title {
            position: relative;

            display: inline-block;
            margin-bottom: 0;


            // Types
            &.centered {
                width: 100%;

                text-align: center;
            }
        }

        .actions {
            position: absolute;
            top: 50%;
            right: rem(20);

            transform: translateY(-50%);


            // Elements
            .button {
                margin-left: rem(10);
            }
        }
    }

    .section-content {
        display: flex;

        padding-bottom: rem(20);


        // Elements
        .form {
            width: auto;
            padding-top: rem(20);
        }
    }


    // Types
    &.is-results {
        max-width: 600px;


        // Types
        &:not(:last-of-type) {
            margin-bottom: rem(45);

            border-bottom: 2px solid $color-primary;
        }


        // Elements
        .form {
            width: 100%;
        }
    }
}
