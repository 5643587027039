/*
    Badge
 */
.badge {
    display: flex;
    justify-content: center;


    // Elements
    .svg {
        display: block;
    }

    &::before {
        display: block;
        width: rem(60);
        height: rem(60);

        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;

        content: '';
    }


    // Types
    &.forgot-password::before {
        background-image: url('/assets/icons/lock-question-mark.svg');
    }

    &.forgot-password-success::before {
        background-image: url('/assets/icons/envelope.svg');
    }

    &.new-account::before {
        background-image: url('/assets/icons/user.svg');
    }

    &.new-password::before {
        background-image: url('/assets/icons/lock-plus.svg');
    }

    &.new-password-success::before {
        background-image: url('/assets/icons/lock-tick.svg');
    }

    &.not-found::before {
        background-image: url('/assets/icons/caution-badge.svg');
    }
}
