/*
    Pagination
 */
.pagination {

    // Elements
    .inner {
        display: flex;
        align-items: center;
        flex-direction: column;

        padding-top: rem(30);
        padding-bottom: rem(30);

        @media (--from-medium-screen-w) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .information {
        display: flex;
        align-items: top;
        flex-shrink: 1;
        justify-content: center;
        width: 100%;
        margin-bottom: rem(20);

        text-align: center;

        @media (--from-medium-screen-w) {
            justify-content: flex-start;
            margin-bottom: 0;
        }


        // Elements
        .text {
            margin-right: rem(20);
            margin-bottom: 0;
        }

        .link {
            flex-grow: 0;
        }
    }

    .no-results {
        width: 100%;

        text-align: center;
    }

    .button-group {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        width: 100%;

        @media (--from-medium-screen-w) {
            justify-content: flex-end;
            min-width: calc(51px * 6); // Hack for IE don't judge
        }

        // Elements
        .button {
            flex-basis: 40px;
            flex-shrink: 0;
            justify-content: center;
            width: 40px;
            height: 40px;

            padding: 0;
            padding-top: rem(4);
            margin-right: 1px;

            color: $color-secondary;
            font-size: rem(16);
            text-align: center;

            background-color: $color-tertiary;
            border: none;
            border-bottom: 4px solid transparent;

            @media (--from-medium-screen-w) {
                flex-basis: 50px;
                width: 50px;
                height: 45px;

                font-size: rem(18);
            }

            // Elements
            &::before {
                display: none;
            }

            .icon {
                font-size: 20px;
            }


            // Types
            &:not(:first-child):not(:last-child) {
                border-radius: 0;
            }

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                margin-right: 0;

                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }


            // States
            &:hover,
            &.is-active {
                border-bottom-color: $color-primary;
                box-shadow: none; // stops focus styles
            }
        }
    }
}
