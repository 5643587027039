/*
    Loading
 */
.is-loading {
    position: relative;


    // Elements
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999; // 4

        display: block;
        width: 100%;
        height: 100%;

        background-color: $color-tertiary;

        content: '';
        opacity: 0.8;
    }

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 99999; // 5

        width: 1em;
        height: 1em;
        margin: -0.9em;

        font-size: 20px;

        border: 0.4em solid rgba(0, 0, 0, 0.1);
        border-left-color: $color-primary;
        border-radius: 100%;

        content: '';

        transform: translateZ(0);
        animation: Spin 1.1s infinite linear;
    }
}


// Animations
@keyframes Spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
