/*
    Heading
 */
h1,
.style-h1,
h2,
.style-h2,
h3,
.style-h3,
h4,
.style-h4,
h5,
.style-h5,
h6,
.style-h6 {
    margin-top: 0;
    margin-bottom: rem(20);

    line-height: 1.2;


    // Styles
    &.thin {
        font-weight: $weight-regular;
    }

    &.medium {
        font-weight: $weight-medium;
    }

    &.pure-pakati {
        font-feature-settings: 'liga' 1, 'calt' 1, 'dlig' 1;
        font-family: $font-secondary;
        font-weight: $weight-regular;
        line-height: 1;

        letter-spacing: 0.05px;
    }

    &.pure-pakati-notch {
        font-family: $font-tertiary;
        font-weight: $weight-regular;
        line-height: 1;

        letter-spacing: 0.05px;
    }


    // Elements
    sup {
        top: 0.7em;
        padding-right: 0.1em;
        font-size: 0.4em;
        vertical-align: top;
    }
}


// Heading groups
h1,
.style-h1,
h2,
.style-h2 {
    font-weight: $weight-bold;
    line-height: 1.1;

    letter-spacing: -1px; // Tweak request by client
}

h5,
.style-h5,
h6,
.style-h6 {
    font-weight: $weight-medium;
}


// Headings
h1,
.style-h1 {
    margin-bottom: rem(20);

    font-size: rem(40);

    @media (--from-tiny-screen-w) {
        font-size: rem(50);
    }

    @media (--from-medium-screen-w) {
        font-size: rem(72);
    }
}

h2,
.style-h2 {
    margin-bottom: rem(20);

    font-size: rem(28);

    @media (--from-tiny-screen-w) {
        font-size: rem(34);
    }

    @media (--from-medium-screen-w) {
        font-size: rem(48);
    }
}

h3,
.style-h3 {
    margin-bottom: rem(10);

    font-size: rem(26);
    font-weight: $weight-regular;
    line-height: 1.2;

    @media (--from-medium-screen-w) {
        font-size: rem(36);
    }
}

h4,
.style-h4 {
    color: $color-secondary-tint-2;
    font-size: rem(18);
    font-weight: $weight-regular;

    @media (--from-medium-screen-w) {
        font-size: rem(20);
    }
}

h5,
.style-h5 {
    margin-bottom: rem(10);

    font-size: rem(16);
    line-height: 1;

    @media (--from-medium-screen-w) {
        font-size: rem(18);
    }
}

h6,
.style-h6 {
    margin-bottom: rem(10);

    font-size: rem(14);
    line-height: rem(16);

    @media (--from-medium-screen-w) {
        font-size: rem(16);
    }
}
