/*
    Filter Bar
 */
.filter-bar {
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;


    // Elements
    input[type='text'],
    select {
        width: 100%;
        height: rem(40);

        background-color: $color-tertiary;
        border: none;
        border-radius: 0;
        box-shadow: none;
        outline: none;

        @media (--from-tiny-screen-w) {
            height: rem(46);
        }

        @media (--from-normal-screen-w) {
            height: rem(52);
        }
    }

    input[type='text'] {
        padding-right: 0;

        @media (--to-small-screen-w) {
            padding-left: rem(8);
            font-size: 16px;
        }
    }

    select {
        flex-shrink: 0;
        max-width: rem(190);
        min-width: rem(92);
        padding-right: rem(32);
        padding-left: rem(10);

        background-image: url('/assets/icons/chevron.svg');
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;
        background-size: 20px auto;

        appearance: none;

        @media (--from-large-screen-w) {
            max-width: rem(240);
            padding-right: rem(35);
            padding-left: rem(15);
        }


        // Hide select arrow
        &::-ms-expand {
            display: none;
        }
    }

    .Select__by-wrapper {
        position: relative;

        flex-shrink: 0;

        background-color: $color-tertiary;


        // Elements
        &::before {
            position: absolute;
            top: rem(10);
            left: rem(10);

            display: block;
            width: calc(100% - rem(20));
            height: calc(100% - rem(20));

            background-color: $color-tertiary-shade-1;

            content: '';
        }

        .input {
            position: relative;

            background-color: transparent;
        }

    }

    .search-input[placeholder] {
        text-overflow: ellipsis;
    }

    .search-input {
        width: 100%;

        padding-left: 0;
    }

    .Select {
        min-width: rem(250);


        // Elements
        .Select__arrow {
            top: rem(22);
        }

        .Select__clear-zone .Select__clear {
            top: rem(10)
        }

        .Select__control {
            height: 100%;
            padding-top: 0;
            border: none;
            border-radius: 0;
        }

        .Select__placeholder {
            padding-top: rem(17);
        }
    }

    .field-group {
        position: relative;

        display: flex;


        // Types
        &:first-child .input,
        &:first-child .Select__control {
            border-left: none;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &.search .Select__by-wrapper {
            @media (--to-normal-screen-w) {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            .input {
                @media (--to-normal-screen-w) {
                    border-left: none;
                }
            }
        }

        &.expand {
            flex-grow: 1;
        }

        &.split-select {
            display: flex;

            .input {
                width: auto;
                min-width: auto;
            }
        }


        // Elements
        .label {
            position: absolute;
            top: -1.2em;

            font-size: rem(13.2);
            line-height: 1;

            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

            @media (--from-normal-screen-w) {
                font-size: rem(18);
            }
        }

        .input:not(.search-input) {
            border-left: 1px solid $color-tertiary-shade-1;
        }

        .submit {
            width: rem(40);
            height: 100%;
            padding-right: rem(10);
            padding-left: rem(10);

            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            @media (--from-normal-screen-w) {
                width: rem(52);
            }


            // Elements
            .button-label {
                display: none;
            }
        }
    }
}
