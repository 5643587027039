/*
    Form
 */
.form {
    max-width: 100%;
    margin-bottom: rem(20);


    // Elements
    .intro,
    .form-errors,
    .fieldset,
    .actions {
        margin-bottom: rem(20);
    }

    .error {
        color: $color-warning;
    }

    .intro {
        text-align: center;


        // Elements
        .title {
            margin-bottom: rem(10);
        }
    }

    .inline-label {
        padding-left: rem(5);
    }

    .field {
        margin-bottom: rem(15);

        transition: opacity 0.2s ease-in-out;


        // Elements
        .input {
            margin-top: rem(3);
            margin-bottom: rem(5);
        }

        .tool-tip {
            margin-left: rem(2);
        }

        .error {
            margin-bottom: rem(10);

            font-size: rem(14);

            // Elements
            &::before {
                position: relative;
                top: 4px;
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: rem(2);

                background-image: url('/assets/icons/danger.svg');
                background-repeat: no-repeat;
                background-size: 100% auto;

                content: '';
            }
        }

        .Select__control > * {
            transition: opacity 0.2s ease-in-out;
            transition-delay: 0.2s;
        }


        // Types
        &.has-error {

            // Elements
            .input:not(:focus),
            .select:not(.is-focused) .Select__control,
            .date-picker:not(.is-focused) .capture {
                border-color: $color-warning;
            }
        }


        // States
        &.is-loading {

            // Elements
            &::before {
                z-index: 1;

                opacity: 0;
            }

            &::after {
                top: 33px;
                right: 32px;
                left: auto;
                z-index: 2;

                width: 8px;
                height: 8px;
                margin-top: 3px;

                border-width: 4px;
            }

            .input {
                pointer-events: none;

                // Elements
                .Select__control > * {
                    opacity: 0;
                }
            }
        }
    }

    .actions {
        text-align: center;


        // Element
        .button {
            margin-bottom: rem(10);
        }
    }

    .allow-none-wrapper {
        position: relative;
    }

    .allow-none {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: rem(100);

        line-height: 1;

        @media (--from-large-screen-w) {
            position: absolute;
            top: 50%;
            left: calc(100% + rem(20));

            transform: translateY(-50%);
        }


        // Elements
        .option-input {
            position: relative;

            display: inline-block;
            width: 20px;
            height: 20px;

            border: 1px solid;
            border-radius: 3px;

            appearance: none;


            // States
            &:checked::before {
                position: absolute;
                top: 50%;
                left: 50%;

                display: block;
                width: 14px;
                height: 14px;

                background-color: $color-secondary;
                border-radius: 2px;

                content: '';

                transform: translate(-50%, -50%);
            }

            &:focus {
                box-shadow: 0 0 5px $color-primary;
                outline: none;
            }
        }

        .option-label {
            padding-left: rem(6);
        }
    }


    // Types
    &.compact {

        // Elements
        .intro,
        .error,
        .note,
        .text {
            text-align: center;
        }
    }


    // States
    &.is-loading {

        // Elements
        .fieldset,
        .actions {
            pointer-events: none;
        }
    }
}
