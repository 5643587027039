/*
    Header
 */
.header {
    background-color: $color-secondary;


    // Elements
    .bottom {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 109px;
        margin-left: 109px;

        @media (--to-medium-screen-w) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .logo {
        position: relative;
        display: block;

        flex-grow: 0;
        flex-shrink: 1;

        margin-top: 25px;
        margin-bottom: 18px;
        margin-left: 15px;
        color: $color-tertiary;
        text-align: center;
        border: none;

        @media (--from-small-screen-w) {
            margin-right: rem(20);
        }

        @media (--to-medium-screen-w) {
            order: 2;
        }

        @media (--from-medium-screen-w) {
            //width: rem(200);
            margin-right: rem(40);
        }

        @media (--from-x-large-screen-w) {
            margin-left: 0;
        }


        // Elements
        .svg {
            position: absolute;
            top: 20%;
            left: 0;

            width: 100%;
            height: 60%;

            @media (--from-medium-screen-w) {
                top: 10%;
                height: 80%;
            }
        }

        .title {
            margin: 8px 0 0 0;
            color: $color-cloud-white;
            font-size: 24px;
            font-weight: $weight-bold;
            text-align: left;
        }
    }

    .button-group {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;
        order: 9;
        height: rem(60);

        @media (--from-medium-screen-w) {
            height: rem(90);
        }

        @media (--from-x-large-screen-w) {
            padding-right: 0;
        }


        // Elements
        .dropdown {
            height: rem(42);

            @media (--to-normal-screen-w) {
                display: none;
            }


            // States
            &:hover > .button.primary {
                border-radius: 3px 3px 0 0;
            }


            // Types
            &.primary {
                height: rem(50);
            }


            // Elements
            .button {
                height: inherit;


                // Types
                &.primary {
                    width: auto;
                    min-width: rem(300);
                    color: $color-tertiary;
                }
            }

            .children .button.primary {
                width: 100%;
                min-width: 0;
            }
        }

        > .button {
            flex-basis: auto;
            width: auto;
            padding-right: rem(20);
            padding-left: rem(20);
            margin-left: rem(10);
            border-radius: 3px;


            // Types
            &.create {
                @media (--to-normal-screen-w) {
                    display: none;
                }
            }
        }
    }

    .links {
        display: flex;
        flex-grow: 1;
        padding-top: rem(2);

        @media (--to-medium-screen-w) {
            order: 1;
            width: 100%;
            padding: rem(5) rem(20) rem(8);

            font-size: rem(14);
            border-bottom: 1px solid $color-secondary-tint-1;
        }

        @media (--from-medium-screen-w) {
            justify-content: flex-end;
        }


        // Elements
        .item {
            margin-right: rem(20);

            color: $color-secondary-tint-2;
            line-height: 1.1;


            // States
            &:hover {
                color: $color-tertiary;
            }
        }
    }

    .navigation {
        flex-basis: 100%;
        height: 102px;

        border-top: 2px solid $color-secondary-tint-1;

        @media (--to-medium-screen-w) {
            order: 2;
        }

        @media (--from-small-screen-w) {
            flex-basis: auto;
            flex-grow: 1;
            height: 102px;

            border: none;
        }

        @media (--from-medium-screen-w) {
            height: 102px;
        }
    }

    .top {
        padding-top: rem(5);
        padding-bottom: rem(5);

        background-color: $color-secondary-tint-1;


        // Elements
        .link-list {
            display: none;

            @media (--from-small-screen-w) {
                display: flex;
            }
        }

        .link-list-trigger {
            padding-top: rem(5);

            @media (--from-small-screen-w) {
                display: none;
            }


            // States
            &.is-open {
                color: $color-tertiary-shade-3;

                .icon {
                    transform: rotate(180deg);
                }

                & + .link-list {
                    display: block;
                }
            }

            // Elements
            .icon {
                width: 1em;
                height: 1em;


                // Types
                &.right {
                    margin-left: rem(4);
                }
            }
        }

        .item {
            display: flex;
            align-items: center;
            padding: 4px 0;
            margin-right: 20px;

            color: $color-tertiary-shade-3;
            font-size: rem(14);

            border: none;
            outline: none;

            @media (--from-medium-screen-w) {
                padding: 5px 0;
            }


            // States
            &:hover {
                color: $color-tertiary;
            }
        }
    }


    // Types
    &.logged-in .links .item {
        margin-right: 0;
    }
}
