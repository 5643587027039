/*
    Table
 */
.table {
    position: relative;

    background-color: $color-tertiary-shade-2;


    .table-inner {
        position: relative;
        z-index: 2;

        width: 100%;
        padding: 0;

        @media (--from-normal-screen-w) {
            display: table;
        }


        // Elements
        .sort {

            display: flex;
            align-items: center;
            justify-content: center;
            min-width: rem(100);
            padding-right: rem(20);
            margin-left: rem(5);
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: $color-shinzo;
            }

            .icon {
                flex-shrink: 0;
            }

            &.asc {
                .icon {
                    transform: rotate(180deg);
                }
            }
        }

        p {
            margin-bottom: rem(10);

            line-height: rem(18);

            @media (--from-normal-screen-w) {
                margin-bottom: 0;
            }
        }

        .row {
            position: relative;

            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            @media (--to-normal-screen-w) {
                padding: rem(20);
                padding-bottom: rem(10);

                border-bottom: 1px solid $color-tertiary-shade-2;
            }

            @media (--from-normal-screen-w) {
                display: table-row;

                transition: 0.2s all ease-in-out;
            }
        }

        .table-body {
            position: relative;

            background-color: $color-tertiary;

            @media (--from-normal-screen-w) {
                display: table-row-group;

                background-color: $color-tertiary-shade-1;
            }


            // Elements
            .cell {

                @media (--from-normal-screen-w) {
                    border-bottom: 1px solid $color-tertiary-shade-2;
                }
            }

            .row {
                outline: none;


                // Types
                &.is-clickable {

                    // States
                    &:hover,
                    &:focus {
                        outline: none;

                        @media (--from-normal-screen-w) {
                            cursor: pointer;

                            background-color: $color-tertiary;


                            // Elements
                            .cell.actions {

                                .status {
                                    display: none;
                                }

                                .button-group {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }

        .table-header {
            display: table-row-group;

            background-color: $color-tertiary-shade-2;


            // Elements
            .cell {

                @media (--from-normal-screen-w) {
                    padding-top: rem(12);
                    padding-bottom: rem(10);

                    white-space: nowrap;
                    vertical-align: top;
                }
            }

            p {
                font-size: rem(16);
                text-transform: uppercase;
            }
        }

        .hide-on-mobile {

            @media (--to-normal-screen-w) {
                display: none;
            }
        }
    }


    // States
    &.is-loading {
        min-height: 400px;


        // Elements
        .cell {
            opacity: 0;
        }

        &::before {
            z-index: 9;

            background-color: $color-tertiary-shade-2;
        }

        &::after {
            top: 140px; // Loading icon higher on the page
        }


        // Relationship
        & + .pagination {
            opacity: 0;
        }
    }
}
