/*
    Layout
    - Constraint
 */


// Constraint
.constrain-width {
    max-width: $constrain-width;
    padding-right: rem(15);
    padding-left: rem(15);
    margin-right: auto;
    margin-left: auto;

    @media (--from-tiny-screen-w) {
        padding-right: rem(20);
        padding-left: rem(20);
    }


    // Sizes
    &.tiny {
        max-width: $constrain-width-tiny;
    }

    &.small {
        max-width: $constrain-width-small;
    }

    &.medium {
        max-width: $constrain-width-medium;
    }

    &.large {
        max-width: $constrain-width-large;
    }
}
