/*
    Label
 */
.label {
    font-size: 1rem;

    // Types
    &.required::after {
        position: relative;
        top: -5px;
        display: inline-block;
        color: $color-required;
        font-weight: $weight-bold;
        content: '*';
    }
}
