/*
    Dropdown
 */
.dropdown {
    position: relative;

    background-color: $color-secondary;


    // States
    &:hover,
    &.is-open {

        .trigger {
            color: $color-primary;


            // Elements
            .chevron {
                transform: rotate(180deg);
            }
        }

        .children {
            width: rem(300);
            height: auto;

            opacity: 1;
            transform: translateY(0);
        }
    }

    // Types
    &.primary {


        // States
        &:hover .children {
            width: 100%;
            margin-left: -50%;
        }
    }

    .table-header & {
        line-height: rem(18);
        background-color: transparent;

        .trigger {
            height: auto;
            color: $color-secondary;

            p {
                display: inline-block;
            }
        }

        .children {
            right: 0;
            left: unset;

            .item {
                cursor: pointer;
            }
        }
    }

    // Elements
    .title {
        padding-right: rem(4);
    }

    .trigger {
        flex-basis: auto;
        height: rem(60);
        min-width: 0;
        padding-right: rem(15);
        padding-left: rem(15);

        color: $color-tertiary;


        // Elements
        .chevron {
            position: relative;
            top: -1px;

            vertical-align: middle;

            transition: all 0.2s ease-in-out;
        }

        ::before {
            display: none;
        }
    }

    .children {
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: 9;

        width: 0;
        height: 0;
        margin-left: -150px;
        overflow: hidden;

        background-color: $color-tertiary;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        opacity: 0;

        transform: translateY(-20px);
        transition: opacity 0.2s, transform 0.2s;



        // Elements
        .wrapper {
            padding: rem(10) rem(20);
        }

        .item,
        .button {
            font-size: rem(18);
        }

        .item:not(.button) {
            display: flex;
            justify-content: center;
            padding: rem(10);
            margin-bottom: 0;

            color: $color-primary;
            text-align: center;
            border-bottom: 1px solid $color-tertiary-shade-2;


            // Elements
            .icon {
                position: relative;
                top: -1px;

                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(18);
                margin-right: rem(4);
            }


            // Types
            &:last-child {
                border-bottom: none;
            }


            //States
            &.is-active,
            &:hover {
                color: $color-secondary-tint-2;
            }
        }

        .button {
            margin: rem(10) 0;
        }
    }
}
