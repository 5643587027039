/*
    Type
 */
html {
    color: $color-secondary-tint-1;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: $weight-regular;
    line-height: 1.5;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Reset
}

p {
    margin-top: 0;
}

strong {
    font-weight: $weight-medium;
}

em {
    font-style: italic;
}

blockquote,
q {
}

cite {
}

address {
}

small {
}
