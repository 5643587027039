/*
    Subpage Banner
 */
.subpage-banner {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 100%;
    height: 200px;

    background-color: $color-secondary;
    background-image: url('/assets/images/banner/subpage.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--from-small-screen-w) {
        height: 240px;
    }

    @media (--from-medium-screen-w) {
        height: 340px;
    }


    // Elements
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 50%;

        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);

        content: '';
    }

    .banner-actions {
        position: relative;

        // Elements
        .title {
            flex-grow: 1;

            color: $color-tertiary;

            h1 {
                margin-bottom: 0;
            }
        }

        .inner {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding-top: rem(20);
            padding-bottom: rem(20);
        }
    }

    .button-dropdown {
        margin-left: rem(20);

        @media (--to-normal-screen-w) {
            display: none;
        }
    }
}
