/*
    Tool Tip
 */
.tool-tip {
    position: relative;
    top: -1px; // Weird vertical alignment adjustment

    display: inline-flex;
    align-items: center;

    font-size: rem(16);
    vertical-align: middle;


    // Elements
    .trigger {
        position: relative;
        z-index: 2;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        color: $color-secondary-tint-2;
        font-size: 0;

        outline: none;
        appearance: none;


        // Elements
        .icon {
            width: 18px;
            height: 18px;
        }


        // States
        &:focus {
            box-shadow: 0 0 5px $color-primary;
        }
    }

    .tool-tip-content {
        position: absolute;
        bottom: calc(100% + 10px);
        left: -13px;
        z-index: 2;

        display: none;
        padding: rem(10) rem(15);

        color: $color-tertiary;
        background-color: $color-primary;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

        transition: all 0.2s ease-in-out;


        // Elements
        &::after {
            position: absolute;
            bottom: -19px; // 1px offset to allow for different browser rendering
            left: 11px;

            display: block;

            border: 10px solid transparent;
            border-top: 16px solid $color-primary;

            content: '';
        }

        .inner-content {
            max-width: calc(50vw);

            line-height: 1.6;


            @media (--from-small-screen-w) {
                white-space: nowrap;
            }

            @media (--from-normal-screen-w) {
                max-width: rem(600);
            }
        }


        // States
        &.is-visible {
            display: block;
        }

        &.is-faux-visible,
        &.is-faux-hidden {
            display: block;

            opacity: 0;
            transform: translateY(20px);
        }

        &.is-hidden {
            display: none;
        }
    }


    // Sizes
    &.large .tool-tip-content .inner-content {
        width: 400px;

        white-space: pre-wrap;
    }


    // Positions
    &.right .tool-tip-content {
        right: -13px;
        left: auto;


        // Elements
        &::after {
            right: 13px;
            left: auto;
        }

        @media (--to-medium-screen-w) {
            right: auto;
            left: -60px;

            &::after {
                right: auto;
                left: 60px;
            }
        }
    }
}
