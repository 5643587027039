/*
    Content Section
 */
.content-section {
    margin-bottom: rem(20);

    border-bottom: 1px solid $color-tertiary-shade-2;


    // Elements
    p:not([class*='style-']), // Avoid changing the size of styled <p> elements
    li {

        @media (--from-medium-screen-w) {
            font-size: rem(18);
        }

        &.small {
            color: $color-secondary-tint-3;
            font-size: rem(14);
        }
    }

    h6,
    .style-h6 {
        margin-bottom: rem(2);
    }

    a {
        color: $color-primary;
    }

    .note {
        margin-bottom: rem(20);

        color: $color-secondary-tint-3;
    }

    .actions {
        margin-bottom: rem(10);


        // Elements
        .back {
            padding-left: 0;
        }

        .status {
            padding: 0;
            margin-bottom: rem(10);

            @media (--from-medium-screen-w) {
                font-size: rem(18);
            }
        }
    }

    .video {
        margin-bottom: rem(20);
    }


    // Types
    &:last-child {
        border-bottom: none;
    }
}
