/*
    Input
 */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    margin: 0;

    appearance: none;
}


input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: rem(10) rem(15);

    font-size: rem(18);
    line-height: 1;

    background-color: $color-tertiary;
    border: 1px solid $color-secondary-tint-2;
    border-radius: 3px;


    // Placeholder
    &::placeholder {
        color: $color-secondary-tint-3;
    }

    // States
    &:required,
    &:invalid {
        box-shadow: none;
        outline: none;
    }

    &:focus {
        border-color: $color-primary;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        outline: none;
    }


    // Types
    &.hidden {
        display: none;
        visibility: none;
    }
}

textarea {
    height: rem(100);
    max-height: rem(400);

    line-height: 1.2;
}
