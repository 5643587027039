.main.user {
    position: relative;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 40px;
    background-color: $color-secondary;
    background-image: url('/assets/images/banner/user-page.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    .main-inner {
        width: 945px;
        max-width: 100%;
        min-height: auto;
        padding: 0 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .page-content {
        padding: 50px 40px;
        margin-right: 74px;
        margin-left: 74px;
        color: $color-cloud-white;
        font-size: 18px;
        font-weight: $weight-medium;
        line-height: 25px;
        background-color: rgba(0, 0, 0, 0.65);

        .page-subheader {
            padding: 0 20px;
            margin-bottom: 60px;
            color: $color-cloud-white;
            font-size: 24px;
            font-weight: $weight-medium;
            line-height: 25px;
            text-align: center;
        }

        .form-actions {
            display: flex;
            justify-content: center;

            .button {
                margin-right: 10px;
                margin-left: 10px;
            }
        }
    }
}

.main.confirm-logout {
    .page-content {
        @media (--from-medium-screen-w) {
            padding: 80px 80px 100px;
        }

        .cancel:before {
            display: none;
        }
    }
}
