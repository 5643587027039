/*
    Alert Dialog
 */
.alert-dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999; // 7

    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    transition: background-color 0.1s ease-in-out;


    // States
    &.is-open {
        display: flex;
    }

    &.is-visible {
        background-color: rgba(0, 0, 0, 0.4);


        // Elements
        .inner {
            opacity: 1;
            transform: translateY(0);
        }
    }


    // Elements
    .inner {
        flex-grow: 0;
        width: rem(600);
        max-width: 100%;
        padding: rem(20);
        margin: 20px;

        background-color: $color-tertiary;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

        opacity: 0;
        transform: translateY(40px);

        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
        transition-delay: 0.2s;

        @media (--from-small-screen-w) {
            padding: rem(40);
        }
    }

    .button-group {
        display: flex;
        justify-content: center;
        margin-right: rem(-10);
        margin-left: rem(-10);

        .button {
            flex-grow: 0;
            margin-right: rem(10);
            margin-left: rem(10);
        }
    }

    .content {
        margin-bottom: rem(20);

        text-align: center;
    }

    .text {
        font-size: rem(18);
    }

    .status-change-filter {
        margin-bottom: rem(20);

        .inline-field {
            margin-bottom: rem(10);
        }

        .check-item {
            display: flex;
            align-items: center;

            width: rem(150);
            margin-right: auto;
            margin-bottom: rem(8);
            margin-left: auto;


            // Elements
            label {
                order: 2;

                font-size: rem(18);
            }

            input[type='checkbox'] {
                order: 1;

                margin-right: rem(10);
            }
        }

        .error {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: rem(10);

            color: $color-quarternary;
            font-size: rem(14);

            // Elements
            &::before {
                display: block;
                width: 18px;
                height: 18px;
                margin-right: rem(2);

                background-image: url('/assets/icons/danger.svg');
                background-repeat: no-repeat;
                background-size: 100% auto;

                content: '';
            }
        }

        select {
            flex-shrink: 0;
            height: rem(40);
            padding-right: rem(40);
            padding-left: rem(10);
            margin: rem(10);

            font-size: rem(18);

            background-color: $color-tertiary;
            background-image: url('/assets/icons/chevron.svg');
            background-position: calc(100% - 10px) center;
            background-repeat: no-repeat;
            background-size: 20px auto;
            border: 1px solid $color-secondary-tint-1;
            border-radius: 3px;

            appearance: none;


            // Hide select arrow
            &:focus {
                outline: none;
            }

            &::-ms-expand {
                display: none;
            }
        }
    }
}
