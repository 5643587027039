/*
    Cell
 */
.cell {
    position: relative;

    @media (--from-normal-screen-w) {
        display: table-cell;
        padding: rem(20);
        padding-right: 0;

        vertical-align: top;
    }

    @media (--from-large-screen-w) {
        vertical-align: middle;
    }


    // Types
    &.cell-default {
        @media (--to-normal-screen-w) {
            width: 100%;
            padding-right: rem(20);
        }
    }

    &.cell-headline {
        @media (--to-normal-screen-w) {
            width: 100%;
        }

        @media (--from-normal-screen-w) {
            max-width: 30vw;
        }


        // Elements
        .cell-headline-inner {
            // To make the text ellipsis work
            display: flex;
            align-items: center;
        }

        .title {
            display: block;
            min-width: rem(30);
            min-height: 0;
        }
    }

    &.cell-publication {

        @media (--to-normal-screen-w) {
            width: 100%;

            color: $color-secondary-tint-3;
        }

        @media (--from-normal-screen-w) {
            max-width: 280px;
        }
    }

    &.cell-market {
        max-width: 180px;

        @media (--to-normal-screen-w) {
            display: none;
        }
    }

    &.cell-media {
        max-width: 80px;

        @media (--to-normal-screen-w) {
            position: absolute;
            right: 12px;
            bottom: 18px;
        }
    }

    &.cell-number {
        text-align: right;
    }

    &.cell-reach {
        text-align: right;

        @media (--to-normal-screen-w) {
            font-size: rem(18);
            text-align: left;
        }

        // Elements
        p::before {
            display: inline-block;
            width: 1.1em;
            height: 1.1em;
            margin-right: rem(1);

            vertical-align: middle;

            background-image: url('/assets/icons/user-colour.svg');
            background-position: 0 -2px; // Tweak alignment
            background-repeat: no-repeat;
            background-size: 1.1em auto;

            content: '';
        }
    }

    &.cell-eav {
        text-align: right;

        @media (--to-normal-screen-w) {
            padding-left: rem(40);

            font-size: rem(18);
            text-align: left;
        }


        // Elements
        &::before {

            @media (--to-normal-screen-w) {
                position: absolute;
                left: rem(20);

                display: block;
                width: 1px;
                height: 1em;

                background-color: $color-tertiary-shade-2;

                content: '';
            }
        }

        span {
            padding-right: 0.2em;

            color: $color-secondary-tint-2;

            @media (--to-normal-screen-w) {
                font-size: 0.9em;
            }


            // Elements
            &::before {

                @media (--to-normal-screen-w) {
                    content: 'NZD';
                }
            }
        }
    }


    // Action Type
    &.actions {
        width: 180px;
        max-width: 180px;
        padding-top: 0;
        padding-bottom: 0;


        // Types
        &.single-button {
            width: 80px;
            max-width: 80px;

            .status,
            .button-group {
                min-width: 60px;
            }
        }


        // Elements
        .status,
        .button-group {
            min-width: 140px;
        }

        .button-group {
            display: none;
            justify-content: flex-end;
            min-height: rem(58);


            // Elements
            .item {
                display: flex;
                align-items: center;
                flex-grow: 1;
                justify-content: center;
                min-height: inherit;
                padding-right: rem(20);
                padding-left: rem(20);

                color: $color-primary;
                text-align: right;

                border: none;

                transition: 0.25s ease-in-out;


                // States
                &:hover {
                    cursor: pointer;
                }

                // Types
                &.delete,
                &.edit {
                    flex-grow: 0;

                    color: $color-tertiary;
                    text-align: center;
                }

                &.delete {
                    background-color: $color-quarternary;

                    &:hover {
                        background-color: $color-quarternary-tint-1;
                    }
                }

                &.edit {
                    background-color: $color-primary;

                    &:hover {
                        background-color: $color-primary-tint-1;
                    }
                }

                &:first-child:last-child {
                    justify-content: flex-end;

                    text-align: right;
                }
            }
        }
    }


    // Elements
    .title {
        display: flex;

        font-weight: $weight-medium;

        @media (--from-normal-screen-w) {
            align-items: center;
            min-height: rem(26);
            overflow: hidden;

            font-size: rem(18);
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .icon {
            display: inline-block;
            margin-right: rem(2);
            margin-left: rem(-5);

            color: $color-secondary-tint-3;

            @media (--to-normal-screen-w) {
                position: relative;
                top: -0.2em;
            }

            @media (--from-normal-screen-w) {
                margin-right: rem(8);
            }
        }
    }

    .icon {
        display: block;
        flex-shrink: 0;
        width: 1em;
        height: 1em;
        max-width: none;

        font-size: 26px;
    }
}
