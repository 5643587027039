/*
    File Upload
 */
.file-upload {
    position: relative;
    padding-top: rem(5);
    padding-bottom: 10px;


    // Elements
    input[type='file'] {
        position: absolute;
        top: 0;
        left: 0;

        width: 0.1px;
        height: 0.1px;

        outline: none;

        opacity: 0;


        // States
        &:focus + .file-label {
            // Since file upload is hidden...
            box-shadow: 0 0 rem(5) $color-primary;
        }
    }

    .button {
        display: inline-flex;
    }
}

.file-upload-list {
    list-style: none;

    border: 1px solid $color-secondary-tint-2;
    border-radius: 3px;


    // States
    &:empty {
        display:none;
    }


    // Elements
    .item {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(8) rem(15);
        margin-bottom: 0;

        line-height: 1;


        // Types
        &:not(:first-child) {
            border-top: 1px solid $color-secondary-tint-2;
        }


        // Elements
        p {
            margin: 0;
        }

        &::before {
            display: none;
        }
    }

    .remove {
        flex-grow: 0;

        color: $color-secondary-tint-3;
        font-size: rem(28);
        line-height: rem(24);

        outline: none;


        // States
        &:hover {
            color: $color-warning;
        }
    }
}
