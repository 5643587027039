/*
    Dashboard
 */
.dashboard {

    // Elements
    .main-inner {
        display: flex;
        flex-direction: column;
        padding: 0;

        background-color: $color-tertiary-shade-2;
    }

    .table-wrapper {
        flex-grow: 1;
    }
}
