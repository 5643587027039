/*
    Style Guide
 */
.style-guide {
    margin-bottom: rem(300);


    // Elements
    .colours {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-left: 0;

        list-style: none;


        // Elements
        li {
            display: block;
            width: rem(60);
            height: rem(60);
            margin-right: $padding-width;
            margin-bottom: $padding-width;

            font-size: 0;


            // Types
            &.light {
                border: 1px solid $color-pure-black;
            }


            // Colours
            &.primary-shade-4 {
                background-color: $color-primary-shade-4;
            }

            &.primary-shade-3 {
                background-color: $color-primary-shade-3;
            }

            &.primary-shade-2 {
                background-color: $color-primary-shade-2;
            }

            &.primary-shade-1 {
                background-color: $color-primary-shade-1;
            }

            &.primary {
                background-color: $color-primary;
            }

            &.primary-tint-1 {
                background-color: $color-primary-tint-1;
            }

            &.primary-tint-2 {
                background-color: $color-primary-tint-2;
            }

            &.primary-tint-3 {
                background-color: $color-primary-tint-3;
            }

            &.primary-tint-4 {
                background-color: $color-primary-tint-4;
            }

            &.secondary {
                background-color: $color-secondary;
            }

            &.secondary-tint-1 {
                background-color: $color-secondary-tint-1;
            }

            &.secondary-tint-2 {
                background-color: $color-secondary-tint-2;
            }

            &.tertiary {
                background-color: $color-tertiary;
            }

            &.tertiary-shade-1 {
                background-color: $color-tertiary-shade-1;
            }

            &.tertiary-shade-2 {
                background-color: $color-tertiary-shade-2;
            }

            &.quarternary {
                background-color: $color-quarternary;
            }

            &.quinary {
                background-color: $color-quinary;
            }

            &.senary {
                background-color: $color-senary;
            }
        }
    }

    .button-group {
        margin-bottom: rem(20);


        // Elements
        .button {
            margin-right: rem(10);
            margin-bottom: rem(10);
        }


        // Types
        &.dark {
            padding: rem(20) rem(20) rem(10);

            background-color: $color-pure-black;
        }
    }

    .section {
        margin-bottom: rem(40);
    }

    .pill {
        margin-right: rem(10);
    }
}
