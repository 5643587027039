/*
    Container
 */
.container {
    position: relative;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100vh;

    overflow: hidden;
}

// Elements
.main {
    display: flex;
    flex-grow: 1;


    // Elements
    .main-inner {
        width: 100%;
        padding: rem(20) 0;

        @media (--from-small-screen-w) {
            min-height: calc(100vh - 65px); // IE fallback
            padding: rem(40) 0 rem(100);
        }

        transition: opacity 0.5s ease-in-out;
    }

    .edit-wrapper {
        display: flex;
    }

    .main-heading {
        width: 100%;
        height: rem(72);
        margin-bottom: rem(30);


        // Elements
        .main-heading-wrapper {
            width: 100%;
            height: inherit;;

            background-color: $color-tertiary-shade-1;
        }

        .main-title {
            display: flex;
            align-items: center;
            height: 100%;


            // Elements
            h3 {
                width: 100%;
                margin-bottom: 0;
            }
        }

        .editable-title {
            padding-left: 0;

            font-size: rem(28);

            background: transparent;
            border: none;

            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                color: $color-secondary;
            }

            &::-ms-clear {
                display: none;
            }
        }


        // Types
        &.is-sticky .main-heading-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10;
        }
    }


    // Types
    &.centered  .main-inner {
        display: flex;
        align-items: center;
    }

    &.is-gapless .main-inner {
        padding-top: 0;
    }


    // States
    &.is-loading {

        // Elements
        &::before {
            opacity: 1;
        }

        &::after {
            position: fixed;
        }

        .page-actions {
            transform: translateY(100%);
        }

        .page-section {
            opacity: 0;
        }
    }
}
