/*
    Tag
 */
.tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: rem(10);

    line-height: 1;

    @media (--from-medium-screen-w) {
        font-size: rem(18);
    }


    // Elements
    .icon {
        width: 1em;
        height: 1em;
        margin-right: 5px;

        font-size: rem(22);

        @media (--from-medium-screen-w) {
            font-size: rem(24);
        }
    }
}
