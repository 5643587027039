/*
    Date Picker
 */
.date-picker {
    position: relative;


    // Elements
    .capture {
        display: flex;
        align-items: center;
        height: 41px;

        border: 1px solid $color-secondary-tint-2;
        border-radius: 3px;
    }

    .date {
        display: flex;
        align-items: center;


        // Elements
        .break {
            position: relative;

            display: block;
            width: rem(10);

            color: $color-tertiary-shade-2;
            text-align: center;

            pointer-events: none;
        }

        .field-group {
            display: flex;
            align-items: center;


            // Elements
            .break {
                left: -3px;

                width: 0;
            }

            input {
                padding-right: rem(5);
                padding-left: rem(5);

                text-align: center;

                border: none;
                border-radius: 0;


                // Order
                &:first-child {
                    padding-left: rem(10);

                    border-radius: 2px 0 0 2px;
                }

                &:last-child {
                    padding-right: rem(10);
                }


                // States
                &:focus {
                    box-shadow: none;
                }


                // Sizes
                &[data-size='2'] {
                    width: rem(40);

                    background-color: transparent;
                }
            }
        }
    }

    .focus-input {
        flex-grow: 1;
        height: inherit;
    }

    .trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 41px;
        height: 41px;


        // Elements
        .svg {
            width: 28px;
            height: 28px;
        }


        // States
        &.is-active {
            color: $color-primary;
        }
    }

    .calendar {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 999;

        display: none;


        // States
        &.is-open {
            display: block;
        }
    }


    // States
    &.is-focused .capture {
        border-color: $color-primary;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    }
}
